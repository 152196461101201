<template>
  <Layout>
    <div>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <!-- Right Sidebar -->
        <div class="col-12">
          <mailSideBar />
          <div class="email-rightbar mb-3">
            <div class="card">
              <div class="btn-toolbar p-3">
                <b-dropdown class="btn-group mr-2 mb-2 mb-sm-0" variant="primary">
                  <template slot="button-content">
                    More
                    <i class="mdi mdi-dots-vertical ml-2"></i>
                  </template>
                  <b-dropdown-item href="javascript: void(0);">Mark as Unread</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Add Star</b-dropdown-item>

                </b-dropdown>
              </div>
              <div class="mt-3">
                <ul class="message-list">
                  <li v-for="(email, index) in paginatedEmailData" :key="index"
                    :class="{ 'unread': `${email.status}` === 'unread' }">
                    <div class="col-mail col-mail-1">
                      <div class="checkbox-wrapper-mail">
                        <input :id="`chk-${index}`" type="checkbox" />
                        <label :for="`chk-${index}`"></label>
                      </div>

                      <a class="title" href="javascript:void(0);"
                        v-on:click="$router.push({ path: '/email/reademail/' + email._id + '/' + $route.name, query: { href: $route.path } })">{{
                          email.email }}</a>
                    </div>
                    <div class="col-mail col-mail-2">
                      <a class="subject" href="javascript:void(0);"
                        v-on:click="$router.push({ path: '/email/reademail/' + email._id + '/' + $route.name, query: { href: $route.path } })">{{
                          email.subject }}</a>
                      <div class="date">{{ convertDate(email.createdAt) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-md-between align-items-md-center">
              <div class="col-xl-7">Showing {{ startIndex }} - {{ endIndex }} of {{ rows }}</div>
              <!-- end col-->
              <div class="col-xl-5">
                <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    @input="onPageChange"></b-pagination>
                </div>
              </div>
              <!-- end col-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import mailSideBar from "@/components/email-components/mailSideBar";
import PageHeader from "@/components/page-header";
import Layout from "../../layouts/main";





export default {
  page: {
    title: "Inbox",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader, mailSideBar },
  data() {
    return {

      paginatedEmailData: null,
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/email"
        },
        {
          text: "Trash",
          active: true
        }
      ],

      // page number
      currentPage: 1,
      // default page size
      perPage: 15,

      // start and end index
      startIndex: 1,
      endIndex: 15,
      showModal: false,
    };
  },
  computed: {
    mails() {
      return this.$store.getters['emails/trashMailsGetter']
    },

    rows() {
      return this.mails.length;
    }
  },

  created() {
    this.startIndex = 0;
    this.endIndex = this.perPage;

    this.paginatedEmailData = this.mails.slice(
      this.startIndex,
      this.endIndex
    );
  },

  watch: {
    mails: {
      handler(newMails) {

        this.paginatedEmailData = newMails.slice(this.startIndex, this.endIndex);
      },
      immediate: true,
    },
  },
  methods: {
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      this.paginatedEmailData = this.mails.slice(
        this.startIndex,
        this.endIndex
      );
    },

    convertDate(date) {
      let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return newDate
    },

  },

  mounted() {
    this.$store.dispatch('emails/getsupportMails')

  }
};
</script>

<style scoped></style>